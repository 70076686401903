<template>
    <section class="new_css">
        <el-row class="new_header">
            <el-col v-if="buttonList.length > 0" :span="24" class="organizetoolbar" style="padding-bottom: 0px">
                <el-form :inline="true" ref="selectForm" :model="selectForm" class="select_form">
                    <el-form-item label="作者:">
                        <el-input v-model="selectForm.person" placeholder="作者"></el-input>
                    </el-form-item>
                    <el-form-item label="论文名称:">
                        <el-input v-model="selectForm.thesisName" placeholder="论文名称"></el-input>
                    </el-form-item>
                    <el-form-item label="期刊名称:">
                        <el-input v-model="selectForm.journalName" placeholder="期刊名称"></el-input>
                    </el-form-item>
                    <el-form-item label="期刊级别:">
                        <el-input v-model="selectForm.journalRank" placeholder="期刊级别"></el-input>
                    </el-form-item>

                    <toolbar :buttonList="buttonList" @callFunction="callFunction" :buttonListmsg="buttonListmsg"></toolbar>
                </el-form>
            </el-col>
        </el-row>
        <div class="calc-height">
            <!--列表-->
            <el-table :data="users"
                      highlight-current-row
                      height="100%"
                      @current-change="selectCurrentRow"
                      @row-dblclick="ondblclick"
                      class="new_table">
                <el-table-column label="序号" prop="RowIndex" width="50" align="center"></el-table-column>
                <el-table-column prop="ThesisName" label="论文名称"> </el-table-column>
                <el-table-column label="期刊名称" prop="JournalName"></el-table-column>
                <el-table-column label="期刊级别" prop="JournalRank"></el-table-column>
                <el-table-column label="发表年度" prop="PublishYear"></el-table-column>
                <el-table-column label="作者单位" prop="AuthorUnit"></el-table-column>
                <el-table-column label="发明人" prop="UserName"></el-table-column>
                <el-table-column label="备注" show-overflow-tooltip prop="Remake">
                </el-table-column>
            </el-table>
        </div>
        <!-- 分页器 -->
        <el-pagination align="center"
                       @current-change="handleCurrentChange"
                       :current-page="currentPage"
                       :page-size="pageSize"
                       layout="prev, pager, next, jumper ,total"
                       :page-count="total"
                       :total="totaldata">
        </el-pagination>
        <el-dialog :title="operation ? '添加' : '编辑'"
                   :visible.sync="SakaryVisible"
                   v-model="SakaryVisible"
                   :close-on-click-modal="false">
            <el-form ref="SakaryForm"
                     :model="SakaryForm"
                     class="demo-form-inline"
                     label-width="100px">
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="论文名称:"
                                      prop="ThesisName"
                                      :rules="[
                { required: true, message: '不能为空', trigger: 'blur' },
              ]">
                            <el-input type="text" v-model="SakaryForm.ThesisName"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="期刊名称:"
                                      prop="JournalName"
                                      :rules="[
                { required: true, message: '不能为空', trigger: 'blur' },
              ]">
                            <el-input type="text" v-model="SakaryForm.JournalName">
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="期刊级别:"
                                      prop="JournalRank"
                                      :rules="[
                { required: true, message: '不能为空', trigger: 'blur' },
              ]">
                            <el-input type="text" v-model="SakaryForm.JournalRank">
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="发表年度:"
                                      prop="PublishYear"
                                      :rules="[
                { required: true, message: '不能为空', trigger: 'blur' },
              ]">
                            <el-date-picker type="date"
                                            v-model="SakaryForm.PublishYear"
                                            value-format="yyyy"
                                            placeholder="选择日期"></el-date-picker>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="作者单位:"
                                      prop="AuthorUnit"
                                      :rules="[
                { required: true, message: '不能为空', trigger: 'blur' },
              ]">
                            <el-input type="text" v-model="SakaryForm.AuthorUnit"> </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row>
                    <el-col :span="24">
                        <el-form-item label="备注">
                            <el-input type="textarea" v-model="SakaryForm.Remark"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="发明人" label-width="120px">
                            <el-button @click="chooseUser()">选择作者</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-table :data="SakaryForm.Persons"
                              style="width: 100%"
                              class="bg-color">
                        <el-table-column prop="UserName" label="姓名"></el-table-column>
                        <el-table-column label="操作" width="150">
                            <template scope="scope">
                                <el-button size="small"
                                           v-on:click="PersonsDelete(scope.row)"
                                           type="text">删除</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-row>
                <upload-files :files="SakaryForm.Files"
                              :key="key"
                              action="/cyl/ftp/ftp/upload"
                              :limit="20"
                              @fun="dealFiles"
                              :IsDisabled="operation1"
                              :IsDel="operation1"></upload-files>
                <el-row v-if="operation1">
                    <el-col :span="24" style="text-align: center">

                        <el-button type="primary" v-on:click="onSubmit('SakaryForm')">提交</el-button>

                    </el-col>
                </el-row>
            </el-form>
        </el-dialog>

        <el-dialog title="选择人员"
                   :visible.sync="addUserVisible"
                   v-model="addUserVisible"
                   width="75%"
                   center
                   append-to-body>
            <UserChoose :data="choosedusers"
                        :all="true"
                        :single="false"
                        @callback="usercallFunction"></UserChoose>
        </el-dialog>
        <thesisModal ref="thesisModal"></thesisModal>
    </section>
</template>
<script>
import util from "../../../util/date";
import * as validate from "../../../util/validate";
import UserChoose from "../../components/UserChoose";
import UserChoosejob from "../../components/UserChoosejob";
import UploadFiles from "../../components/UploadFiles";
import {
  ThesisGetInfoPageList,
  ThesisAddInfo,
  ThesisUpdateInfo,
  ThesisGetInfoList,
} from "../../api/hr";
import { getButtonList } from "../../promissionRouter";
    import Toolbar from "../../components/newToolbar";
    import thesisModal from "../componentModals/thesisModal"    // 论文

export default {
        components: { Toolbar, UserChoose, UserChoosejob, UploadFiles,thesisModal },
  data() {
    return {
      loading: true,
      searchVal: "",
      currentPage: 1, // 当前页码
      total: null, // 总条数
      totaldata: null,
      pageSize: 20, // 每页的数据条数
      page: 1,
      key: 0,
      SakaryVisible: false,
      buttonListmsg: "",
      users: [],
      choosedusers: [],
      Intochoosedusers: [],
      operation: false, // true:新增, false:编辑
      operation1: true,
      addUserVisible: false,
      IntoUserVisible: false,
      buttonList: [],
      SakaryForm: {
        ThesisName: null,
        JournalName: null,
        JournalRank: null,
        PublishYear: null,
        AuthorUnit: null,
        Remark: null,
        Persons: [],
        Files: [],
      },
      selectForm: {
        thesisName: "",
        journalName: "",
        journalRank: "",
        person: "",
      },
    };
  },
  methods: {
    selectCurrentRow(val) {
      this.currentRow = val;
    },
    formatStartTime: function (row, colume) {
      var date = row[colume.property];
      if (date == undefined) {
        return "";
      }
      return util.formatDate.format(new Date(date), "yyyy-MM-dd");
    },
    PersonsDelete(row) {
      this.$confirm("确认要删除吗？", "提示", {
        type: "warning",
      }).then((_) => {
        this.SakaryForm.Persons.splice(this.SakaryForm.Persons.indexOf(row), 1);
      });
    },
    usercallFunction(newdata) {
      for (var i = 0; i < newdata.length; i++) {
        this.SakaryForm.Persons.push({
          UserName: newdata[i].UserNameClaim,
          UserId: newdata[i].Id,
        });
      }

      //  this.SakaryForm.TalkPersons = userStr;
      this.addUserVisible = false;
    },
    inputMoney(name) {
      if (!validate.validateIsMoney(this.SakaryForm[name])) {
        this.$message({
          message: "请输入金额",
          type: "error",
          duration: 5000,
        });
        this.SakaryForm[name] = null;
        return;
      }
    },
    chooseUser() {
      this.addUserVisible = true;
    },

    callFunction(item) {
      this[item.Func].apply(this, item);
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.page = val;
      this.getData(false);
    },
    //获取列表
    getData(isCurrent = true) {
      if (isCurrent) {
        this.page = 1;
      }
      let para = {
        page: this.page,
        size: this.pageSize,
        journalRank: this.selectForm.journalRank,
        journalName: this.selectForm.journalName,
        thesisName: this.selectForm.thesisName,
        person: this.selectForm.person,
      };

      ThesisGetInfoPageList(para).then((res) => {
        console.log(res);
        this.total = res.data.response.pageCount;
        this.totaldata = res.data.response.dataCount;
        this.users = res.data.response.data;
        this.loading = false;
      });
    },
    getButtonList2(routers) {
      let _this = this;
      routers.forEach((element) => {
        let path = this.$route.path.toLowerCase();
        if (element.path && element.path.toLowerCase() == path) {
          _this.buttonList = element.children;
          return;
        } else if (element.children) {
          _this.getButtonList(element.children);
        }
      });
    },
    handleEdit() {
      let row = this.currentRow;
      if (!row) {
        this.$message({
          message: "请选择要编辑的一行数据！",
          type: "error",
        });
        return;
      }
      ThesisGetInfoList({ id: row.Id }).then((res) => {
        console.log(res);
        this.SakaryForm = res.data.response;
        this.key += 1;
        this.SakaryVisible = true;
      });

      this.operation = false;
      this.operation1 = true;
    },
    handleShow() { // 双击显示
      let row = this.currentRow;
      if (!row) {
        this.$message({
          message: "请选择要查看的一行数据！",
          type: "error",
        });
        return;
      }
      ThesisGetInfoList({ id: row.Id }).then((res) => {
        console.log(res);
        this.SakaryForm = res.data.response;
        this.key += 1;
        this.SakaryVisible = true;
      });

      this.operation = false;
      this.operation1 = false;
    },
    ondblclick(row, column, cell, event) {
        this.$refs.thesisModal.onShowDlg(row.Id);
    },
    handleAdd() {
      (this.SakaryForm = {
        ThesisName: null,
        JournalName: null,
        JournalRank: null,
        PublishYear: null,
        AuthorUnit: null,
        Remark: null,
        Persons: [],
        Files: [],
      }),
        (this.key += 1);
      (this.operation = true), (this.SakaryVisible = true);
      this.operation1 = true;
    },
    onSubmit(formName) {
      this.$confirm("确定要提交吗？", "提示", {}).then(() => {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            let para = Object.assign({}, this.SakaryForm);
            if (this.operation) {
              //新增
              ThesisAddInfo(para).then((res) => {
                if (res.data.success) {
                  this.$message({
                    message: "添加成功",
                    type: "success",
                    duration: 5000,
                  });
                  this.SakaryVisible = false;
                  this.getData();
                } else {
                  this.$message({
                    message: res.data.msg,
                    type: "error",
                    duration: 5000,
                  });
                }
              });
            } else {
              //修改
              ThesisUpdateInfo(para).then((res) => {
                if (res.data.success) {
                  this.$message({
                    message: "修改成功",
                    type: "success",
                    duration: 5000,
                  });
                  this.SakaryVisible = false;
                  this.getData(false);
                } else {
                  this.$message({
                    message: res.data.msg,
                    type: "error",
                    duration: 5000,
                  });
                }
              });
            }
          } else {
            this.$message({
              message: "请检查必填项",
              type: "error",
              duration: 5000,
            });
            return false;
          }
        });
      });
    },
    dealFiles(data) {
      console.log(data.backData);
      this.SakaryForm.Files = data.backData;
    },
  },
  mounted() {
    this.getData();
    let routers = window.localStorage.router
      ? JSON.parse(window.localStorage.router)
      : [];
    let buttons = window.localStorage.buttList
      ? JSON.parse(window.localStorage.buttList)
      : [];
    this.buttonList = getButtonList(this.$route.path, routers, buttons);
  },
};
</script>
<style scoped>
.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 100%;
}
</style>

